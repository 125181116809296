import http from '@plugins/http.js'
class MedicalManagementAPI {
  getList(status) { // 获取列表
    return http.get(`/medical-list?status=${status}`)
  }
  addMed(params) { // 新建科目
    return http.post(`/medical`, params)
  }
  updateMed(params) { // 更新科目
    return http.put(`/medical`, params)
  }
  delMed(id) { // 删除科目
    return http.delete(`/medical/${id}`)
  }
}
export default new MedicalManagementAPI()
